import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from 'vue-router'
import {urlreq} from "../assets/js/urlreq.js";

Vue.use(router);

Vue.use(Vuex);

export const wsutil = new Vuex.Store({
    state : {
        jwtToken : localStorage.getItem("jwtToken") || null,
        rfhToken : localStorage.getItem("rfhToken") || null,
        apiBaseUrl : "https://tng.snatchpark.com/",
        curAct : "",
        temp: "",
        userProfile : {},
        tableRow: [],
        accounts: []
    },
    getters : {
        getJwt : state =>{
            return state.jwtToken;
        },

        getAccounts (state){
          return state.accounts;
        }
    },
    mutations : {
      storeToken(state, token2) {
        //store new jwt and refresh token
        //localStorage.setItem("jwtToken", token);
        state.jwtToken = token2.token;
      },
      clearToken(state) {
        localStorage.removeItem("jwtToken");
        state.jwtToken = null;
      },
      getAccount(state, accounts) {
        state.accounts = accounts
      },
    },
    actions : {
        userLogout(context, {router}){
            //this.$router.replace({ name: "Login" });
            context.commit('clearToken');
            router.push({ name: "Login" });
        },
        userLogin(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

            let rq = {"username": credentials.username,"password": credentials.password};

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'login', JSON.stringify(rq) )
                .then(response => {
                    if (response.data.token){
                        //Store new token and call again the api
                        context.commit('storeToken', response.data.token);
                        localStorage.setItem("jwtToken", response.data.token);
                    }
                    else{
                        context.commit('clearToken');
                    }
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        getSystem(){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");
          return new Promise((resolve, reject) => {
            let userUrl = 'admin/system'

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllCarPark(){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");

          return new Promise((resolve, reject) => {
            let userUrl = 'admin/carpark'

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getCarPark(context, credentials){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");

          let selsys = credentials.sel_system ? '/' + credentials.sel_system + '/' : "/";
          return new Promise((resolve, reject) => {
            let userUrl = 'admin/system' +selsys+ 'carpark'

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        genCarparkToken(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

            let rq = {"systemID": credentials.systemID,"carparkID": credentials.carparkID, "name": credentials.name};

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/tokens', JSON.stringify(rq) )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        getGeneratedCarparkTokenDetails(context, credentials){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");
          return new Promise((resolve, reject) => {
            let userUrl = 'admin/tokens?active=1&systemID=' + credentials.systemID + '&carparkID=' + credentials.carparkID

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllTokens(context, credentials){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");
          let page = credentials.curPage > 0  ? 'page=' + (credentials.curPage - 1) : "";
          let isActive = credentials.active >= 0  ? 'active=' + credentials.active : "";
          let sys_selected = credentials.system_selected ? 'systemID=' + credentials.system_selected : "";
          let carpark_selected = credentials.carpark_selected ? 'carparkID=' + credentials.carpark_selected : "";
          let sort = credentials.sort != "" ? 'sort=' + credentials.sort : "";

          let r = [page, isActive, sys_selected, sort, carpark_selected];

          let urlReq = urlreq.createReq(r);

          return new Promise((resolve, reject) => {
            let userUrl = 'admin/tokens' + urlReq;

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        resetToken(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/tokens/'+credentials.tokenID+'/reset', {} )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        updateToken(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/tokens/'+credentials.tokenID+'/update', {name: credentials.name, carparkID: credentials.carpark, systemID: credentials.system} )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        deleteToken(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';
          
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/tokens/'+credentials.tokenID+'/delete', {} )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        getAllTxn(context, credentials){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");
          return new Promise((resolve, reject) => {
            
            let filtersys = credentials.systemID ? '&systemID=' + credentials.systemID : "";
            let filtercarpark = credentials.carparkID ? '&carparkID=' + credentials.carparkID : "";
            let sync = credentials.notSync != "" ? '&notSync=' + credentials.notSync : "";
            let txn_search = credentials.search ? '&search=' + credentials.search : "";
            let sort = credentials.sort != "" ? '&sort=' + credentials.sort : "";
            let start = credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? "" : `&start=${credentials.transactionstartdate}`;
            let end = credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? "" : `&end=${credentials.transactionenddate}`;
            let tokenID = credentials.tokenID ? '&tokenID=' + credentials.tokenID : "";

            console.log(sort)

            let userUrl = 'admin/trxn?page=' + (credentials.curPage - 1) + filtersys + filtercarpark + sync + txn_search + sort + start + end + tokenID;

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },
        
        getAllLaneMapping(context, credentials){
          axios.defaults.headers.token = localStorage.getItem("jwtToken");
          return new Promise((resolve, reject) => {
            
            let filtersys = credentials.systemID ? '&systemID=' + credentials.systemID : "";
            let filtercarpark = credentials.carparkID ? '&carparkID=' + credentials.carparkID : "";
            let txn_search = credentials.search ? '&search=' + credentials.search : "";
            let sort = credentials.sort != "" ? '&sort=' + credentials.sort : "";
            let start = credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? "" : `&start=${credentials.transactionstartdate}`;
            let end = credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? "" : `&end=${credentials.transactionenddate}`;

            console.log(sort)

            let userUrl = 'admin/lanemap?page=' + (credentials.curPage - 1) + filtersys + filtercarpark + txn_search + sort + start + end;

              axios.get(wsutil.state.apiBaseUrl + userUrl)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        createLaneMapping(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

          let req = {
            "tokenID": credentials.tokenID,
            "laneName": credentials.laneName,
            "laneCode": credentials.laneCode,
            "carparkID": credentials.carparkID
          };

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/lanemap/new', req )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        updateLaneMapping(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

          let req = {
            "id": credentials.id,
            "tokenID": credentials.tokenID,
            "laneName": credentials.laneName,
            "laneCode": credentials.laneCode,
            "carparkID": credentials.carparkID
          };

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/lanemap/edit', req )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        syncTrans(){
          axios.defaults.headers.post['Content-Type'] = 'application/json';
          
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'admin/trxn/sync', {} )
                .then(response => {
                  resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },
    }
});