<template>
  <div class="app flex-row align-items-center" id="kpmg-login">
    <div class="container">
      <transition name="fade">
        <div class="spinner-container" v-if="loading">
            <b-spinner label="Spinning" style="position: relative; top: 45%;"></b-spinner>
        </div>
      </transition>
      <b-row class="justify-content-center">
        <b-col md="10">

            <b-card-group>
              <transition appear name="slide-fade2">
              <b-card no-body class="py-5 hide-mobile" style="background: transparent">
                <b-card-body class="text-center">
                  <b-row class="justify-content-center">
                    <img src="../assets/logo.png" height="150"/>
                    <h1 class="font-weight-bold" style="color: #fff; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: #0B2D65;"><u>System Snatch</u></h1>
                  </b-row>
                </b-card-body>
              </b-card>
              </transition>
              <transition appear name="slide-fade">
              <b-card no-body class="p-4">
                <b-card-body>
                  <b-form action="#" @submit.prevent="validateBeforeSubmit">
                    <div class="show-mobile text-center">
                      <img src="../assets/logo.png" height="150"/>
                      <h1 class="font-weight-bold" style="color: #fff; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: #0B2D65;"><u>System Snatch</u></h1>
                    </div>
                    <h1>Login</h1>
                    <p class="text-muted">Login with your account</p>
                    <b-form-group>
                      <b-input-group :class="{ 'form-group--error': $v.username.$error }">
                      <b-input-group-prepend><b-input-group-text><b-icon icon="person-fill"></b-icon></b-input-group-text></b-input-group-prepend>
                      <b-form-input type="text" class="form-control" name="username" id="username" v-model.trim="$v.username.$model" placeholder="Username" autocomplete="username email" />
                    </b-input-group>
                      <div class="error" v-if="$v.username.$anyError">Username is required</div>
                    </b-form-group>
                                        
                    <b-form-group>
                    <b-input-group :class="{ 'form-group--error': $v.password.$error }">
                      <b-input-group-prepend><b-input-group-text><b-icon icon="lock-fill"></b-icon></b-input-group-text></b-input-group-prepend>
                      <b-form-input type="password" class="form-control" name="password" id="password" v-model="password" placeholder="Password" autocomplete="current-password" />
                    </b-input-group>
                      <div class="error" v-if="$v.password.$anyError">Password is required</div>
                    </b-form-group>
                    <b-row>
                      <b-col cols="12">
                        <b-button type="submit" block variant="primary" class="px-4">Login   <b-spinner v-if="loading" variant="light" label="Spinning" small></b-spinner></b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
              </transition>
            </b-card-group>
        </b-col>
      </b-row>
    </div>
    <footer class="app-footer" style="position: absolute; width: 100%; bottom: 0">
      <!-- Footer content here -->
      <small>Copyright By Snatch Park Services Sdn Bhd © 2020</small>
    </footer>
  </div>
</template>

<script>
import { required, minLength, between } from 'vuelidate/lib/validators'

  export default {
  name: 'Login',
  
  data() {
    return {
      username: "",
      password: "",
      error: "",
      loading: false,
    }
  },

  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
    
  methods: {
    userLogin(){
      this.loading = true
      this.$store.dispatch("userLogin", {
          username: this.username,
          password: this.password
        })
        .then(response => {
          console.log(response);
          if (response.data.token)
          {
            this.$router.go({ name: "Home" });
          }
          else
          {
            this.loading = false;
            this.error = "Incorrect Login Credentials!"
          }
        })
        .catch(error => {
          this.loading = false;

          if (JSON.parse(error.response.request.response).message == 'not match'){
                            this.$bvToast.toast('Incorrect Login details. Please try again.', {
                            title: `Error`,
                            variant: 'danger',
                            solid: true
                            })
                        }
        });
    },
    validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.userLogin();
      }
    }
  }
}
</script>

<style lang="scss">
#kpmg-login .container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
  #kpmg-login img{
    width: 200px;
    object-fit: contain;
    padding: 1.25rem;
  }
  #kpmg-login.app{
    //background: linear-gradient(to bottom, #b8c6df 0%, #6d88b7 100%);
    background: -moz-linear-gradient(45deg, #58595b 0%, black 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #58595b ), color-stop(100%, black )); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, #58595b 0%, black 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, #58595b 0%, black 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, #58595b 0%, black 100%); /* ie10+ */
    background: linear-gradient(45deg, #58595b 0%, black 100%); /* w3c */
  }
  .show-mobile{
    display: none;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .show-mobile{
      display: block;
    }
    .hide-mobile{
      display: none;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .slide-fade-enter-active {
    transition: all 1s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100px);
    opacity: 0;
  }
  .slide-fade2-enter-active {
    transition: all 1s ease;
  }
  .slide-fade2-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade2-enter, .slide-fade2-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-100px);
    opacity: 0;
  }


  .form-group--error{
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;

    input{
      border-color: #f79483;
      margin-bottom: 0;
    }
  }

  .form-group--error + .form-group__message, .form-group--error + .error {
    display: block;
    color: #f57f6c;
    text-align: left;
    font-size: 0.75rem;
  }

  @keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }
</style>