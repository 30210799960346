<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'

export default {

  data() {
    return {
      toaster: false,
      toastertxt: '',
      toasterColor: 'red',
    }
  },
    
    created() {
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response && error.response.data) {
              this.$store.dispatch('userLogout',{
                        router: this.$router
                  });
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error.message);
        });
    }
}
</script>

<style lang="scss">
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import './assets/scss/style';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
