export const urlreq = { createReq : function(rq) {
    var urlReqArr = [];
    var newUrlReqArr = [];

    for (var i=0; i<rq.length; i++){
        if (rq[i] != ''){
            urlReqArr.push(rq[i]);
        }
    }

    for(var i=0; i<urlReqArr.length; i++){
          if (i == 0){newUrlReqArr.push('?' + urlReqArr[i]); }
          else{newUrlReqArr.push('&' + urlReqArr[i]); }
    }

    let urlReq = newUrlReqArr.toString().replace(/,/g, '');

    return urlReq;
  }

}