<template>
  <div class="app">
  <header class="app-header navbar">
    <a class="navbar-brand" href="#">
    <img src="../assets/logo.png" width="90" height="40" alt="System Snatch">
    </a>
      <button class="navbar-toggler sidebar-toggler mr-auto" type="button" data-toggle="sidebar-sm-show">
    <span class="navbar-toggler-icon"></span>
    </button>
    <b-nav>
      <b-nav-item disabled>
        <!-- <b-img v-bind="mainProps" rounded="circle" alt="Circle image" class="mr-3"></b-img> -->
        <b-button title="Reset Token" pill size="md" variant="success" class="mr-1">P</b-button>
          Welcome
      </b-nav-item>
    </b-nav>

  </header>
  <div class="app-body">
    <div class="sidebar">
  <nav class="sidebar-nav ps">
    <ul class="nav">
      <li class="nav-title">System Snatch v0.1</li>
      <li class="nav-item" v-for="(item, index) in nav" :key="index">
        <b-link class="nav-link" :to="item.url" v-bind:class="{ active: item.url == $route.path ? 'isActive' : '' }">
          <i class="nav-icon" :class="item.icon"></i> {{item.name}}
        </b-link>
      </li>
      <!--
      <li class="nav-item">
        <a class="nav-link" href="#">
          <i class="nav-icon cui-speedometer"></i> With badge
          <span class="badge badge-primary">NEW</span>
        </a>
      </li>
      <li class="nav-item nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" href="#">
          <i class="nav-icon cui-puzzle"></i> Nav dropdown
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="nav-icon cui-puzzle"></i> Nav dropdown item
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="nav-icon cui-puzzle"></i> Nav dropdown item
            </a>
          </li>
        </ul>
      </li>
      -->
      <li class="nav-item mt-auto">
        <b-link class="nav-link nav-link-danger" @click="logoutHandler">
          <i class="nav-icon fa fa-power-off"></i> Logout
        </b-link>
      </li>
    </ul>
  </nav>
</div>
    <main class="main">
      <router-view />
    </main>
  </div>
  <footer class="app-footer">
    <!-- Footer content here -->
    <small>Copyright By Snatch Park Services Sdn Bhd © 2020</small>
  </footer>
</div>
</template>

<script>
// @ is an alias to /src
import nav from '@/nav/nav'

export default {
  name: 'home',

  data() {
        return{
          mainProps: { blank: true, blankColor: '#777', width: 30, height: 30, class: 'm1' },
          nav: nav.items,
          isActive: true
        }
  },

  methods: {
    logoutHandler() {
            //this.userLogout({ router: this.$router });
            this.$store.dispatch('userLogout',{
                router: this.$router
            })
        },
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/variables";

  html:not([dir=rtl]) .sidebar-sm-show .sidebar{
    margin-left: 0;
  }

  html:not([dir=rtl]) .sidebar{
    margin-left: -200px;
  }

  .sidebar .nav-link{
    text-align: left !important;
  }

  a.navbar-brand{
    //width: 200px;
    img{
      width: 100px;
      object-fit: contain;
    }
  }
  .text-grey{
    color: $grey;
  }
  .kpmg-table{
    td{
      vertical-align: middle;
    }
    .table-social{
      span{
        border-radius: 6px;
        margin-right: 5px;
        font-size: 1.875rem;
        cursor: pointer;
      }
    }
    .table-action{
      color: $grey;
      span{
        //padding: 8px 12px;
        //border: 1px solid $grey;
        border-radius: 6px;
        margin-right: 5px;
        font-size: 1.875rem;
        color: $kpmgblue;
        cursor: pointer;
      }
      span:hover{
        color: $kpmghoverblue;
        border-color: $kpmghoverblue;
      }
    }
  }
/*
  .spinner-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: steelblue;
    opacity: .9;
    z-index: 999;
  }
  .spinner{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  */
  .swal2-container {
    z-index: 9999;
  }
  .card-button{
    cursor: pointer;
    transition: all .2s ease-in-out;
    .card-button-icon{
      text-align: center;
      font-size: 50px;
      color: $kpmgblue;
    }
    .card-button-icon:hover{
      color: $kpmghoverblue;
    }
  }
  .card-button:hover {
    border: 3px solid $kpmghoverblue;
    transform: scale(1.05);
    transition: all .2s ease-in-out;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .s-link{
    background-color: whitesmoke;
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 100%;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    button{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      opacity: 1;
    }
  }
.table-action{
  .fa-star{
    color: gold;
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }
  .fa-star:hover{
    color: darkgoldenrod;
  }
  .fa-edit{
    color: $kpmgblue;
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }
  .fa-edit:hover{
    color: $kpmghoverblue;
  }
  .fa-trash{
    color: red;
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }
  .fa-trash:hover{
    color: darkred;
  }
  .fa-clipboard{
    color: $kpmgblue;
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }
  .fa-clipboard:hover{
    color: $kpmghoverblue;
  }
  span{
    padding-right: 5px;
  }
}
  select{
    cursor: pointer;
  }
  table#table-transition-example .flip-list-move {
    transition: transform 1s;
  }
</style>