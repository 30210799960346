import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import {wsutil} from './store'
import { BootstrapVueIcons, BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@coreui/coreui';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate'
import CoreuiVue from '@coreui/vue';
import VCalendar from 'v-calendar';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(CoreuiVue);
Vue.use(Vuelidate);

Vue.config.productionTip = false

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

const options = {
  confirmButtonColor: '#00519A',
  cancelButtonColor: '#ff7674'
}

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2, options);

new Vue({
  router,
  store: wsutil,
  render: h => h(App)
}).$mount('#app')
