import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login'

let entryUrl = null;

const guard = async (to, from, next) => {
    if (localStorage.getItem("jwtToken")) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url); // goto stored url
        } else {
            return next(); // all is fine
        }
    }

    if (localStorage.getItem("jwtToken")) {
        next();
    } else {
        entryUrl = to.path; // store entry url before redirect
        next('/');
    }
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
          next('/home');
      }

      else{
          next();
      }
    },
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/home/tokengen',
    children: [
      {
        path: 'tokengen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/CarParkListing.vue'),
        beforeEnter: guard, // Using guard before entering the route
      },

      {
        path: 'tokenlist',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/TokenListing.vue'),
        beforeEnter: guard, // Using guard before entering the route
      },

      {
        path: 'transaction',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/TransactionListing.vue'),
        beforeEnter: guard, // Using guard before entering the route
      },

      {
        path: 'lanemapping',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/LaneMapping.vue'),
        beforeEnter: guard, // Using guard before entering the route
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
