export default {
    items: [
      {
        name: 'Token Generator',
        url: '/home/tokengen',
        icon: 'fa fa-parking'
      },

      {
        name: 'Token Listing',
        url: '/home/tokenlist',
        icon: 'fa fa-list'
      },

      {
        name: 'Lane Mapping',
        url: '/home/lanemapping',
        icon: 'fa fa-map-pin'
      },

      {
        name: 'Transaction',
        url: '/home/transaction',
        icon: 'fa fa-file-invoice-dollar'
      }
    ]
  }